.space30 {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
}
.col_w1000 {
    width: 1000px;
    margin: 0 auto;
    overflow: hidden;
}
