.sliderContainer {
    width: 100%;
    height: 539px;
    overflow: hidden;
    position: relative;
    & [ifeng_ui_pc_sliders-dots] {
        bottom: 22px;
        position: absolute;
        z-index: 3;
        right: 50%;
        margin-right: -430px;
    }
}
.sliderWraper {
    width: 3000px;
    height: 539px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    /* transform: translateX(-50%); */
    margin-left: -1500px;
    & [ifeng_ui_pc_sliders-arrows_pre] {
        position: absolute;
        left: -50%;
        margin-left: -500px;
        position: absolute;
        z-index: 10;
        top: 0;
        width: 100%;
        height: 538px;
        cursor: pointer;
        margin-top: 0;
        filter: alpha(opacity=80);
        opacity: 0.8;
        background: #47505f url(http://y0.ifengimg.com/a/2016/0406/left.png) no-repeat 99.5%;
        *+background: #47505f url(http://y0.ifengimg.com/a/2016/0406/left.png) no-repeat 100%;
    }
    & [ifeng_ui_pc_sliders-arrows_next] {
        left: 50%;
        margin-left: 500px;
        position: absolute;
        z-index: 10;
        top: 0;
        width: 100%;
        height: 538px;
        cursor: pointer;
        margin-top: 0;
        filter: alpha(opacity=80);
        opacity: 0.8;
        background: #47505f url(http://y0.ifengimg.com/fe/gjzk/images/right_52d3e1eb.png) no-repeat 0.5%;
        *+background: #47505f url(http://y0.ifengimg.com/fe/gjzk/images/right_52d3e1eb.png) no-repeat 0 50%;
    }
    & [ifeng_ui_pc_sliders-item] {
        /* margin-right: 1px; */
        & a {
            display: block;
        }
    }
    /* & div:nth-child(1) div:last-child {
        overflow: visible;
    } */
}
.dot {
    width: 20px !important;
    height: 3px !important;
    margin-right: 5px !important;
}
.current {
    width: 20px !important;
    height: 3px !important;
    margin-right: 5px !important;
    background-color: #eb5e01 !important;
}
.bg {
    display: block;
    width: 977px;
    height: 231px;
    background: url(http://y0.ifengimg.com/fe/gjzk/images/bj_9530a5b2.png) no-repeat left top;
    _background: none;
    _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(enabled=true,sizingMethod=scale,src="http://y0.ifengimg.com/fe/gjzk/images/bj_9530a5b2.png");
    _width: 977px;
    _height: 231px;
    margin-left: 0px;
    margin-bottom: 0px;
    position: absolute;
    bottom: 0;
    padding-left: 23px;
}
.new {
    height: 22px;
    line-height: 22px;
    position: absolute;
    bottom: 138px;
}
.title {
    font-family: 微软雅黑, 黑体;
    width: 953px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    color: #eeeeee;
    font-size: 36px;
    position: absolute;
    left: 23px;
    bottom: 90px;
    overflow: hidden;
}
.summary {
    width: 610px;
    font-size: 12px;
    color: #909090;
    position: absolute;
    bottom: 30px;
    line-height: 24px;
}
