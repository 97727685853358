body {
    font-size: 12px;
    font-family: simsun, arial, helvetica, clean, sans-serif;
    background: #fff;
    color: #2b2b2b;
}
.space90 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
}
.footcolumn {
    background: #fafafa;
    padding-top: 30px;
}
