.box {
    width: 1000px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}

.footer {
    background: #47505f;
    width: 100%;
    margin: 0 auto;
    height: 80px;
}
