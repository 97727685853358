.col_1000 {
    width: 1000px;
    overflow: hidden;
}
.space31 {
    height: 31px;
    line-height: 31px;
    overflow: hidden;
}
.zkcp_title {
    width: 1000px;
    height: 29px;
    overflow: hidden;
}
