.content {
    width: 1000px;
    overflow: hidden;
}
.prosItem {
    width: 33.3333%;
    float: left;
    background: url(http://y0.ifengimg.com/fe/gjzk/images/sg_bed4e5fd.jpg) no-repeat right top;
    overflow: hidden;
    position: relative;
}
.prosItem1 {
    width: 33.3333%;
    float: left;
    overflow: hidden;
    position: relative;
}
.zk_tu {
    width: 334px;
    height: 93px;
    text-align: center;
}
.zk_tit {
    margin-top: 25px;
    width: 334px;
    text-align: center;
    height: 26px;
    color: rgb(71, 80, 95);
    font-size: 26px;
    font-family: 微软雅黑;
    line-height: 26px;
    overflow: hidden;
}
.zk_h {
    width: 276px;
    overflow: hidden;
    margin-top: 12px;
    margin-left: 28px;
    & h2 {
        width: 276px;
        overflow: hidden;
        & a {
            font-size: 18px;
            color: rgb(43, 43, 43);
            font-family: 微软雅黑;
            line-height: 26px;
        }
    }
    & p {
        color: #828282;
        font-size: 12px;
        line-height: 22px;
    }
}
.zk_h_even {
    position: absolute;
    top: 40px;
    width: 276px;
    overflow: hidden;
    margin-top: 12px;
    margin-left: 28px;
    & h2 {
        width: 276px;
        overflow: hidden;
        margin-bottom: 30px;
        & a {
            font-size: 18px;
            color: rgb(43, 43, 43);
            font-family: 微软雅黑;
            line-height: 26px;
        }
    }
    & p {
        color: #828282;
        font-size: 12px;
        line-height: 22px;
    }
}
.block {
    display: block;
}
