.newsItem {
    width: 370px;
    overflow: hidden;
    margin-top: 25px;
    & h1 {
        font-size: 14px;
        & a {
            font-family: 微软雅黑;
            font-size: 18px;
            color: rgb(71, 80, 95);
        }
    }
    & p {
        color: #5a5a5a;
        line-height: 22px;
        margin-top: 4px;
    }
}
.zz {
    color: #aeaeae;
    width: 370px;
    & span {
        margin-right: 6px;
        & b {
            font-family: Verdana, Geneva, sans-serif;
            font-weight: normal;
            line-height: 28px;
        }
    }
}
