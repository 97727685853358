.menu {
    width: 100%;
    border-top: solid 1px #e8e8e8;
    background: url(http://y0.ifengimg.com/fe/gjzk/images/bjj_16b58eb1.jpg) repeat-x left top;
}

.menu_w1000 {
    width: 1000px;
    height: 77px;
    margin: 0 auto;
}

.logo {
    float: left;
    width: 210px;
    height: 40px;
    margin-top: 18px;
}

.menu_r {
    float: right;
    width: 574px;
    height: 77px;
    overflow: hidden;

    & ul {
        float: right;
    }

    & ul li {
        background: url(http://y0.ifengimg.com/fe/gjzk/images/sx_8f739d8b.jpg) no-repeat right center;
        height: 75px;
        float: left;
        display: inline-block;

        & .active {
            background: url(http://y0.ifengimg.com/fe/gjzk/images/bj_63b88cf7.jpg) repeat-x left top;
            display: inline-block;
            color: #fff;

            & span {
                color: #c1c9de;
            }
        }

        & a {
            font-size: 16px;
            color: rgb(43, 43, 43);
            font-family: 微软雅黑;
            padding-top: 18px;
            display: inline-block;
            height: 59px;
            padding-left: 10px;
            padding-right: 17px;
            text-decoration: none !important;

            & span {
                display: block;
                font-family: Arial, Helvetica, sans-serif;
                margin-top: 5px;
                font-size: 12px;
                color: #aeaeae;
            }
        }

        &:hover {
            & a {
                background: url(http://y0.ifengimg.com/fe/gjzk/images/bj_63b88cf7.jpg) repeat-x left top;
                display: inline-block;
                color: #fff;

                & span {
                    color: #c1c9de;
                }
            }
        }
    }
}
