.col_w1000 {
    width: 1000px;
    margin: 0 auto;
    overflow: hidden;
}
.col_left {
    width: 392px;
    float: left;
    border-right: solid 1px #ebebeb;
    height: 492px;
    margin-left: 4px;
}
.title_left {
    background: url(https://x0.ifengimg.com/ucms/2020_50/094877C26F474AAD7516942C3C3BCC98E0F23ED8_w392_h47.jpg)
        no-repeat left center;
    width: 392px;
    height: 47px;
    & a {
        font-family: Verdana, Geneva, sans-serif;
        font-size: 12px;
        color: #aeaeae;
        float: right;
        margin-right: 20px;
        margin-top: 20px;
        text-decoration: none;
    }
}
.col_m {
    width: 278px;
    margin-left: 19px;
    float: left;
    border-right: solid 1px #ebebeb;
}
.title_m {
    width: 278px;
    height: 47px;
    background: url(http://y0.ifengimg.com/fe/gjzk/images/w_260_3c4140e6.jpg) no-repeat left center;
    & a {
        font-family: Verdana, Geneva, sans-serif;
        font-size: 12px;
        color: #aeaeae;
        float: right;
        margin-right: 20px;
        margin-top: 20px;
        text-decoration: none;
    }
}
.space26 {
    height: 26px;
    line-height: 26px;
    overflow: hidden;
}
.tp {
    width: 260px;
    height: 183px;
    position: relative;
    margin-top: 9px;
}
.tp_bj {
    position: absolute;
    background: #74849e;
    width: 260px;
    height: 30px;
    bottom: 0px;
    background: #74849e;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    opacity: 0.8;
    left: 0px;
}
.tp_zi {
    position: absolute;
    color: #dedede;
    bottom: 0px;
    width: 220px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    left: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px 0 20px;
    white-space: nowrap;
    & a {
        color: #dedede;
    }
}
.col_right {
    width: 278px;
    float: left;
    margin-left: 19px;
}
.title_r {
    background: url(https://x0.ifengimg.com/ucms/2020_50/2CE923FD5A705215CE67751C787A5213141A5AA4_w392_h47.jpg)
        no-repeat left center;
    height: 47px;
    & a {
        font-family: Verdana, Geneva, sans-serif;
        font-size: 12px;
        color: #aeaeae;
        float: right;
        margin-top: 20px;
        text-decoration: none;
    }
}
.col_r_wrapper {
    width: 278px;
    overflow: hidden;
    margin-top: 26px;
    & ul li {
        height: 36px;
        line-height: 36px;
        width: 278px;
        overflow: hidden;
        & a {
            color: #2b2b2b;
            font-size: 14px;
            text-decoration: none;
        }
        & a:hover {
            color: #f54343;
        }
    }
}
